@import '../../assets/global.scss';

.Contact{
  @include bp(ft){&.tpad{padding-top:20px;}}

  .mainFr{grid-column-gap:30px;
    .fcItem{@include sec-default();margin-bottom:12px;
      &:last-child{margin-bottom:0;}
    }
    .ia span, .dot{margin-top:13px;}
    .subFr .is{text-align:center;}

    @include bp(og){
      .ia span, .dot{background-color:$white;}
      .subFr .is{text-align:left;}
    }

    @include bp(oc){flex-direction:column;align-items:unset;
      .subFr{justify-content:center;
        .ip{max-width:150px;}
        .is{max-width:100px;text-align:center;}
      }
    }
    @include bp(sm){.ie p{line-height:1.2em;}}
    @media (max-width:340px){
      .ia, .ih {
        span{width:100%;height:0;margin:0;display:block;}
      }
    }
  }

  .formsec{padding:70px 0 50px;
    .med3Def{max-width:740px;}
    @include bp(tw){padding:50px 0 40px;}
    @include bp(nn){padding:40px 0 30px;
      .med3Def{max-width:680px;}
    }
    @include bp(ft){padding-bottom:20px;
      .med3Def{max-width:450px;}
    }
  }

  .reviewSec{padding-bottom:70px;
    @include bp(tw){padding-bottom:50px;
      .inner{width:93%;}
    }
    @include bp(og){padding-bottom:30px;}
  }
}