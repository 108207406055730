@import "../../assets/global";

.Form{@include sec-default();
  .formGroup{@include sec-default(); margin:15px 0;}
  .comitem{display:inline-block;width:48%;}
  .comitem:nth-child(even){float:right;}
  
  label{font-size:16px;line-height:1.4em; margin-bottom:3px; font-weight:500;}
  input, textarea{
    width:100%;
    padding:7px 5px 5px 7px;
    border:0; border-bottom:3px solid rgba($black, .1);
    font-size:15px;
    font-family:$body;
    background-color:$fa;
    &:focus{background-color:darken($fa, 3%); outline:none; border-color:rgba($color2, .6);}
  }
  input{min-height:25px;}
  textarea{min-height:100px;}
  .ssLink{text-transform:uppercase;font-weight:bold;letter-spacing:1px;float:right;}

  @include bp(ft){
    .comitem{@include sec-default();//margin:15px 0;
      &:nth-child(odd){margin-bottom:15px;}
      &:nth-child(even){margin-top:15px;}
    }
  }
}
.tourform{padding:15px 30px;border-radius:25px;background-color:rgba($bgLt2, .7);
  input{background-color:darken($bgLt2, 2%);
    &:focus{background-color:$white;}
  }

  @include bp(ft){
    padding:10px 20px;
  }
}