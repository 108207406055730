@import '../../assets/global.scss';

.footWrap{padding-top:100px; position:relative;z-index:0;
  .footerSec{position:relative;z-index:0;@include sec-bgimage('./images/kellerExteriorFooter2.jpg');
    &::before{@include before();background-color:rgba($color1,.97);}
  }
  &, p, li, a{color:$white;}

  .wave1{margin-top:-90px;
    svg{height:115px;}
  }
  .top{padding:20px 0 30px;}
  .topWrap{width:95%;max-width:1500px;display:flex;align-items:center;justify-content:space-between;}
  .ftGroup.contact{text-align:center;}
  .fcItem+.fcItem{margin-top:12px;}
  ul{list-style-type:none;padding-left:0;}

  button{padding:0;}
  .ia{
    p{margin-bottom:2px;}
    span{margin:13px 3px 0;background-color:$white;}
  }
  
  .btm{text-align:center;background-color:darken($color1, 5%);
    p{font-size:14px;margin:12px 0;}
    br{display:none;}
  }

  @include bp(dt){
    .menu{max-width:350px;display:inline-flex;flex-wrap:wrap;
      text-align:center; justify-content:space-evenly;
    }
    ul{display:inline-block;}
    li{display:inline-block;margin:7px 12px;}
  }

  @include bp(tw){
    .top{padding:0 0 10px;}
    .topWrap{width:90%;max-width:1000px;}
    li{margin:12px 0;}
    .menu{min-width:200px;}
    .menu2{text-align:right;}
  }
  @include bp(nn){padding-top:80px;
    .wave1{margin-top:-75px;
      svg{height:80px;}
    }
    .top{
      &, li, a, p{font-size:15px;}
    }
  }
  @include bp(og){padding-top:50px;
    .wave1{margin-top:-45px;
      svg{height:50px;}
    }
    .footerSec{background-size:cover;}
    .top{padding-top:15px;}
    .topWrap{max-width:470px;}
    .ftGroup.contact{width:100%;}
    .menu{display:none;}

    .btm{background-color:darken($color1, 5%);}
    .btm br{display:block;}
  }
  @include bp(sm){.btm br{display:none;}}
  @include bp(xx){
    .top{
      li, a, p{word-wrap:break-word;}
    }
  }
}