@import '../../assets/global.scss';

.headerSec{@include sec-default();
  .mainHeader{@include sec-default();}
  .infoRow{font-size:15px;
    .trFlex{background-color:$color2;padding:15px 20px;}
    &, a{color:$white;}
    button, a{line-height:1em;}

    .trGroup, .trItem{position:relative;z-index:0;}
    .trItem{display:inline-block;margin:0;
      &:not(:last-child){padding-right:33px;}
      &::after{position:absolute;z-index:-1;opacity:.5;
        content:'/';width:33px;height:100%;top:0;right:0;text-align:center;
      }
      &:last-child::after{display:none;}
      button{padding:0;}
    }
  }

  .navRow{display:flex;align-items:center;justify-content:space-between;}
  .logo{max-width:300px;}
  .nav{padding:0;margin-top:30px;
    .mobOnly{display:none;}
  }
  li{list-style-type:none;}
  .navItem{display:inline-block;margin:0 10px;}
  
  .dropWrap{
    visibility:hidden;opacity:.8;
    position:absolute;z-index:99;
    width:100%;max-width:220px;
    padding:10px 5px 10px 15px;
    background-color:$bgLt3;
    box-shadow:0px 10px 30px rgba($black, 0.2);
  }
  .dropMenu{padding:0;}
  li.dropItem{font-size:16px;@include sec-default();margin:8px 0;}
  li.parent{color:$color1;
    button{padding:0;}
    svg{width:20px;height:20px;vertical-align:middle;}
   
    &:hover .dropWrap{visibility:visible;opacity:1; transition: all 1s;}
  }

  .topbar{margin-top:20px;}
  &.vtopbar .mainHeader{padding:25px 0 35px;}
  &.vbtmbar .mainHeader{padding:15px 0 25px;}
  &.vbtmbar:not(.longNav) .navRow{justify-content:space-evenly;}
  // KPKP current .longNav rules are all for hidden home link

  .mobNav{display:none;position:absolute;right:-40px;bottom:5px; // right:10%;
    button{color:$color1;}
  }

  @include bp(tw){
    &.topbar:not(.longNav) .navRow{justify-content:space-evenly;}
   
    &.longNav{
      .navRow{justify-content:space-around;}
      .navItem{font-size:16px;}
    }
  }
  
  @include bp(br){
    &.longNav{
      .mainHeader{padding:8px 0 15px;}
      .mhWrap{max-width:380px;}
      .navRow{position:relative;justify-content:center;}
      .navWrap{display:none;}
      .mobNav{display:block;}
      .infoRow{
        .trFlex{padding:10px;justify-content:center;}
        .links{padding-left:33px;
          &::after{position:absolute;z-index:-1;opacity:.5;
            content:'/';width:33px;height:100%;top:0;left:0;text-align:center;
          }
          .trItem::after, li:last-child{display:none;}
        }
      }
    }
  }

  @include bp(ft){
    &.vtopbar .mainHeader, &.vbtmbar .mainHeader{padding:8px 0 15px;}
    .mhWrap{max-width:380px;}
    .navRow{position:relative;justify-content:center;}
    .navWrap{display:none;}
    .mobNav{display:block;}
    .infoRow{
      .trFlex{padding:10px;justify-content:center;}
      .links{display:none;}
    }
  }
  @include bp(oc){
    .logo{max-width:270px;}
    .mobNav{right:-20px;bottom:0;}
  }
  @include bp(fi){
    .infoRow{display:none;}
    .logo{max-width:230px;}
    button{padding:0;}
    .mobNav{right:5px;}
    
    &.vtopbar .mainHeader, &.vbtmbar .mainHeader{padding-bottom:0px;}
  }
  @media (max-width:375px){
    .mhWrap, &.longNav .mhWrap{width:95%;max-width:275px;}
    &.longNav .navRow, .navRow{justify-content:space-between;}
    .logo{width:90%;}
    .mobNav{right:0px;}
  }

  @media (max-width:300px){
    .navRow{padding:0 10px;}
    .logo{max-width:200px;}
  }
}

.SideNavList{padding:30px 25px 0 25px;width:98%;max-width:300px;
  .closeIcon{position:absolute;right:10px;top:10px;cursor:pointer;font-weight:$normal;
    svg{width:23px;height:23px;margin-bottom:2px;}
  }
  
  &, li, a, button, p{color:$white;}
  ul{padding-left:0;}
  li{@include sec-default();line-height:1em;margin-bottom:20px;
    button{padding:0;line-height:1em;}
  }
  .drawerNav{font-size:18px;}
  .enrollLi{@include sec-default();margin-top:10px;padding-top:30px;border-top:2px solid rgba($white,.5);
    &, a{font-size: 17px;}
  }

  .coninfo{margin-top:10px;padding-top:30px;border-top:2px solid rgba($white,.5);
    &, a{font-size: 16px;}
  }
  .mobOnly{display:block;}
  .fwOnly{display:none;}

  @include bp(sm){padding-left:20px;padding-right:20px;}
}
