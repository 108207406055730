@import '../../assets/global.scss';

.Home{
  @include bp(fi){padding-top:15px;}

  .homeStack{margin-bottom:20px;position:relative;z-index:0;
    &::before, &::after{@include before();width:50%;border-right:10px solid $white;}
    &::after{background-color:$black;opacity:.3;}
    &::before{@include sec-bgimage('./images/homeIntro1.jpg');background-position:bottom right;background-size:cover;z-index:-2;}

    .borderb{border-bottom:10px solid $white;}
    .bordert{border-top:10px solid $white;}
    .borderl{border-left:10px solid $white;}
    .borderr{border-right:10px solid $white;}

    .hlf{display:inline-block;width:50%;}
    .mhLeft{padding:30px 30px 0;float:left;
      .textWrap{width:90%;}
    }
    .mhRight{float:right;}

    .rtop, .rtwo, .image{position:relative;z-index:0;}

    .rtop{
      .image::before{@include before();background-color:$black;opacity:.4;z-index:0;}
      img{position:relative;z-index:-1;}
      .text{position:absolute;padding:30px;bottom:0;z-index:2;}
    }
    .rtwo{
      .image::before{@include before();background-color:$black;opacity:.4;z-index:0;}
      img{position:relative;z-index:-1;}
      .text{position:absolute;bottom:0px;padding:30px 30px 20px;z-index:2;}
      button{padding-top:12.5px;padding-bottom:13.5px;}
    }
    .textblk{padding:100px 40px 0px;}

    .h1, .h2, p{font-family:$fun;}
    .h1{color:$white;text-shadow:0px 1px 2px rgba($black, .7);}
    .h2{font-size:29px;color:$white;text-shadow:0px 1px 2px rgba($black, .5);line-height:1.3em;}
    .hdline{
      font-size:35px;margin-bottom:22px;line-height:1.2em;
      color:$white;text-shadow:0px 1px 1px rgba($black, .5);
    }

    img.mob{display:none;}

    @media (max-width:1400px){
      .h1{font-size:40px;}
      .h2{font-size:26px;}
    }
    @include bp(tw){
      .h1{font-size:34px;}
      .h2{font-size:24px;}
      .hdline{font-size:33px;margin-bottom:15px;}
      .ssLinkRev{padding:7px 18px 9px;}
      .rtwo button{padding:9px 18px 11px;}
      
      .rtop .image::before{opacity:.6;}
    }
    @include bp(nn){
      &::before, &::after, .borderb, .bordert, .borderl, .borderr{border-width:5px;}
      &::after{opacity:.5;}
      .h1{font-size:30px;}
      .h2{font-size:22px;}
      .hdline{font-size:27px;}

      .mhLeft{padding:20px 15px 0;
        .textWrap{width:95%;}
      }
      .rtop .text{padding:0 20px 30px;}
      .rtwo{
        .image::before{opacity:.6;}
        .text{bottom:3px;}
      }
    }
    @include bp(og){
      &::before, &::after{display:none;}
      .hlf{width:100%;}
      .textWrap{width:95%;max-width:450px;@include center-block();text-align:center;}
      .h1{font-size:27px;
        text-shadow:none;color:$color2;margin-bottom:10px;
      }
      img.mob{display:block;}
      img.fw{display:none;}
    }
    @include bp(oc){
      .textWrap{max-width:420px;}
      .h1{font-size:25px;}
      .h2{font-size:20px;}
      .hdline{font-size:24px;}
      .ssLinkRev{padding:5px 15px 7px;}
      .rtwo button{padding:7px 15px 9px;}

      .rtop .text{padding-left:15px;padding-right:15px;}
      .rtop, .rtwo{background-color: $color1;}
    }
    @media (max-width:450px){
      .hdline{font-size:22px;margin-bottom:10px;}
      img.mob{display:none;}
      img.fw{display:block;}
    }
  }

  @include bp(nn){
    .stackOuter{width:95%;}
  }

  .inlineText{padding:55px 0 70px;
    .textGroup{align-items:center;justify-content:space-between;}
    p:last-child{margin-bottom:0;}
    .hd p{font-size:50px;font-family:$fun;text-transform:uppercase;line-height:1.3em;font-weight:$bold; color:$color1;}
    .content{padding-left:60px;
      p{font-size:18px;}
    }
    span{font-weight:bold;}

    @include bp(tw){padding-top:40px;
      .hd p{font-size:45px;}
      .content{padding-left:45px;
        p{font-size:17px;}
      }
    }
    @include bp(nn){padding:15px 0 30px;text-align:center;
      .textGroup{flex-direction:column;}
      .hd p{font-size:30px;margin-bottom:7px;
        br{display:none;}
      }
      .content{padding-left:0;}
    }
    @include bp(og){
      .hd p{font-size:27px;}
      .content p{font-size:16px;}
    }
  }

  .blocksRow{margin-bottom:60px;
    .blocksWrap{max-width:1200px;display:flex;justify-content:space-between;}
    p{margin-bottom:0;}

    .block{
      // // inline // //
      // width:33%;max-width:360px;display:inline-flex;grid-column-gap:14px;align-items:center;margin-bottom:30px;padding:6px 0;

      // // centered // //
      // width:33%;max-width:350px;text-align:center;
      // .icon{@include center-block();}
      // .icon, svg{width:40px;max-width:40px;height:40px;}
      // svg{color:$pal1;}

      // // centered + bg / border radius // //
      width:32%;max-width:380px;padding:25px 30px 40px;margin-bottom:30px;background-color:$bg2;text-align:center;
      border-top-right-radius:40px;border-bottom-left-radius:40px;
      .icon{@include center-block();}
      // .icon, svg{width:40px;max-width:40px;height:40px;}
      .icon, svg{width:50px;max-width:50px;height:50px;}
      svg{color:$pal1;}


      // testing sprites THURS STARTER  ... left top
      // padding-top:15px;
      // .icon img{display:none;}
      // .icon{background-image:url('../../assets/images/iconsB.png');background-size:auto;
      //   width:50px; height:46px;
      //   // border:1px solid gray;
      // }
      // .icon.school{background-position:2px 0;}
      // .icon.art{background-position:181px 0;}
      // .icon.pack{background-position:-601px 45px;}
    



    }
    .blocksWrap{
      width:95%;max-width:100%;
      justify-content:center;grid-column-gap:20px;
    }

    @include bp(tw){margin-bottom:40px;
      .blocksWrap{justify-content:space-between;}
      .block{padding:25px 20px 40px;}
    }
    @include bp(nn){margin-bottom:20px;
      .block{padding:10px 15px 20px;}
    }
    @include bp(og){
      .blocksWrap{max-width:450px;flex-direction:column;}
      .block{width:100%;max-width:100%;margin-bottom:15px;}
      .block{padding-left:20px;padding-right:20px;}
    }
  }


  .reviewBg{padding:25px 0 10px;text-align:center;background-color:$color2;
    p{color:$white;}
    p:first-child{font-size:20px;}

    @include bp(tw){padding:15px 0 5px;}
    @include bp(br){
      p:first-child{font-size:18px;}
    }
    @include bp(og){
      .med2Def{width:95%;}
      p:first-child{font-size:17px;}
    }
  }
  .wave1 svg, .wave2 svg{height:120px;}
  .wave1+.reviewBg{margin-top:-10px;}
  .reviewBg+.wave2{margin-top:-5px;}
  @include bp(br){.wave1 svg, .wave2 svg{height:90px;}}
  @include bp(og){.wave1 svg, .wave2 svg{height:75px;}}
  
  .letter{padding:50px 0 60px;
    .fin{font-size:19px;}
    .fun{font-size:25px;font-weight:$hbold;font-style:italic; color:$color1}
    p.fun{margin-bottom:7px;}

    .group{margin:20px 0 30px;}
    .image, .text{display:inline-block;}
    .image{width:60%;float:left;margin-left:-7%;}
    .text{width:50%;float:right;margin-right:-7%;margin-top:6%;}

    @media (min-width:1365px){
      .image{width:65%;margin-left:-10%;}
      .text{width:51%;margin-right:-10%;}
    }


    @include bp(tw){padding:20px 0 30px;
      .group{margin-top:15px;}
      .image{width:55%;margin-left:-4%;}
      .text{width:49%;margin-right:-3%;margin-top:3%;}  
    }
    @include bp(nn){padding:0px 0 20px;
      .fin{font-size:17px;}
      .fun{font-size:22px;}
      .group{margin-top:10px;margin-bottom:0px;}
      .image{position:sticky;top:10px;}
      .text{margin-top:5px;}
    }
    @include bp(og){padding-bottom:0px;
      .fin{font-size:16px;}
      .fun{font-size:20px;}

      // .group{margin-top:10px;margin-bottom:0px;}
      .image{display:none;}
      .text{margin-top:0px;width:100%;margin-right:0;}
    }
  }
}