@import '../../assets/global';

.stackHeader{
  .stackWrap{max-width:1400px;}
  &.major .stackWrap{position:relative;}

  .image{position:relative;z-index:0;}
  .text{position:absolute;z-index:2;background-color:$white;}
  h1{font-family:$fun;}
  p:last-child{margin-bottom:0;}

  @include bp(og){
    &::before, &::after{@include before();width:90%;left:5%;}
    &::after{@include sec-bgimage2();background-size:cover;z-index:-2;}

    .image{display:none;}
    .text{
      width:100% !important;
      bottom:unset !important;right:unset !important;
      position:relative;
      background-color:transparent;
    }
  }
  @include bp(fi){&::before, &::after{width:95%;left:2.5%;}}
}

.shAbout{
  .image{width:85%;}
  .text{width:45%;max-width:475px;padding:40px 0 0 40px;bottom:0;right:0;}
  h1{font-size:33px;color:$color2;line-height:1.3em;font-weight:normal;
    span{color:$color1;}
  }
  
  @include bp(tw){
    .image{width:80%;}
    .text{width:50%;max-width:450px;}
    h1{font-size:30px;} 
  }
  @include bp(nn){
    .text{width:80%;max-width:400px;padding:25px 0 0 30px;}
    h1{font-size:27px;} 
  }
  @include bp(og){position:relative;z-index:0;
    &::before{background-color:rgba($black,.55);}
    &::after{background-image: url('../../assets/images/aboutHeader.jpg');}
    .text{max-width:600px;padding:38% 20px 20px 25px;}
    h1{font-size:24px;color:$white;
      span{color:lighten($color2, 8%);}
    }
  }
  @include bp(ph){
    .text{padding-top:34%;max-width:400px;}
    h1{font-size:20px;}
    br{display:none;}
  }
}

.shCareers{position:relative;z-index:1;
  .image{width:80%;}
  .text{
    width:70%;max-width:600px;
    padding:40px 30px 40px 40px;
    bottom:-40px;right:-2%;
  }
  h1{font-size:33px;color:$color2;line-height:1.3em;font-weight:normal;
    span{color:$color1;}
  }

  @include bp(tw){
    .image{width:85%;}
    .text{width:90%;max-width:560px;}
    h1{font-size:30px;} 
  }
  @include bp(nn){
    .text{max-width:465px;padding:25px 30px 25px 30px;}
    h1{font-size:25px;} 
  }
  @include bp(og){
    &::before{background-color:rgba($black,.6);}
    &::after{background-image: url('../../assets/images/careersHeader.jpg');}    
    .text{max-width:600px;padding:270px 20px 20px;}
    h1{font-size:24px;color:$white;
      span{color:lighten($color2, 8%);}
    }
  }
  @include bp(oc){
    .text{max-width:550px;padding:240px 20px 20px;}
    h1{font-size:22px;}
  }
  @include bp(ph){
    .text{padding-top:100px;}
    h1{font-size:20px;}
  }
}

.shProgram{position:relative;z-index:1;
  .image{width:85%;}
  .text{
    width:70%;max-width:600px;
    padding:40px 30px 40px 40px;
    bottom:-40px;right:2%;
  }
  h1{margin-bottom:20px;color:$color1;}
  h2{font-size:20px;text-transform:uppercase;letter-spacing:5px;font-weight:900;line-height:1.2em;margin:0;color:$color2;}

  @media (min-width:1795px){
    .stackWrap{position:relative;}
    .text{right:-2%;}
  }

  @include bp(tl){
    h1{font-size:39px;}
    h2{font-size:19px;}
  }
  @include bp(tw){
    .text{width:80%;max-width:700px;}
    h1{font-size:34px;}
    h2{font-size:18px;letter-spacing:4px;}
  }
  @include bp(nn){
    .image{width:88%;}
    .text{width:85%;max-width:660px;padding:25px 25px;}
    h1{font-size:25px;margin-bottom:12px;}
    br{display:none;}
    h2{font-size:17px;letter-spacing:2px;font-weight:bold;}
  }
  @include bp(og){
    &::before{background-color:rgba($black,.7);}
    &::after{background-image: url('../../assets/images/programHeader.jpg');background-position:bottom center;}
    .text{padding:26% 20px 20px;}
    h1, p{color:$white;}
    h1{font-size:24px;}
    h2{font-size:16px;letter-spacing:1px;margin-bottom:5px;color:lighten($color2, 8%);}
  }
  @include bp(oc){
    .text{padding-top:22%;}
    h1{font-size:22px;}
    br{display:block;}
  }
}

.shContact{
  .image{width:80%;}
  .text{
    width:70%;max-width:650px;
    padding:40px 0 0 40px;
    bottom:0;right:0;
  }

  h1{font-size:50px;color:$color1;margin-bottom:0px;text-transform:uppercase;}
  p{margin-bottom:0;}
  p, p a{color:$black;}
  svg{width:24px;}

  @include bp(tw){
    .image{width:85%;}
    .text{padding-right:10px;}
    h1{font-size:45px;}
  }
  @include bp(nn){
    .text{width:90%;max-width:620px;padding:25px 10px 0 30px;}
    h1{font-size:40px;}
    svg{width:22.5px;}
  }

  @include bp(og){position:relative;z-index:0;
    &::before{background-color:rgba($black,.75);}
    &::after{background-image: url('../../assets/images/contactHeader.jpg');}
    .text{max-width:680px;padding:27% 15px 20px;}

    h1, p, p a, svg, a{color:$white;}
  }

  @include bp(oc){text-align:center;
    .text{padding-top:15%;}
    h1{font-size:24px;margin-bottom:15px;}
  }

  @include bp(sm){
    h1{font-size:21px;}
    p{word-wrap: break-word;}
  }
}

.shResources{
  .image{width:85%;}
  .text{width:60%;right:0; 
    // max-width:555px;bottom:0;padding:30px 0 0 40px; //no bg
    max-width:565px;bottom:-40px;padding:30px 15px 35px 40px; //with bg
  }
  h1{color:$color2;margin-bottom:20px;
    span{color:$color1;}
    br{display:none;}
  }

  @include bp(tl){h1{font-size:40px;}}
  @include bp(tw){
    .text{width:80%;max-width:700px;}
    h1{font-size:33px;margin-bottom:15px;
      br{display:block;}
    }
  }
  @include bp(nn){
    .image{width:88%;}
    // .text{width:90%;padding-top:25px;padding-left:25px;} //no bg
    .text{width:93%;max-width:680px;padding:25px 15px 25px 25px;bottom:-35px;} //with bg
    h1{font-size:25px;margin-bottom:10px;
      br{display:none;}
    }
  }
  @include bp(og){position:relative;z-index:0;
    &::before{background-color:rgba($black,.65);}
    &::after{background-image: url('../../assets/images/resourcesHeader.jpg');}
    .text{padding:26% 20px 20px;}
    h1, p{color:$white;}
    h1{
      span{color:lighten($color2, 8%);}
      br{display:block;}
    }
  }
  @include bp(oc){
    .text{padding:22% 10px 15px 15px;}
    h1{font-size:22px;}
  }
  @include bp(sm){
    .text{padding-left:10px;}
  }
}