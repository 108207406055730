@import './variables.scss';
@import './mixins.scss';


h1, h2, h3, h4, h5, p{margin-top:0;color:$black;font-family:inherit;
  &.fun, span.fun{font-family:$fun;}
  &.up, span.up{text-transform:uppercase;}
  &.cap, span.cap{text-transform:capitalize;}
  &.em, span.em{font-style:italic;}
  &.bold, span.bold{font-weight:bold;}
  &.cat, span.cat{
    font-size:16px;letter-spacing:1px;
    font-weight:bold;line-height:1.1em;
    font-family:$body;text-transform:uppercase;margin-bottom:0;
  }
  &.white, span.white, b.white{color:$white;}
  &.bmargin0{margin-bottom:0;}
  &.red, b.red, span.red{color:$red;}
}
h1{font-size:45px;margin-bottom:inherit;line-height:1.2em;}
h2{line-height:1.6em;font-weight:$normal;}
p, h4, h5{margin-bottom:20px;}
p{font-size:17px;line-height:1.6em;}
@include bp(nn){
  p{font-size:16px;}
}

a{text-decoration:inherit;
  color:$color1;
  &:hover{opacity:.8;@include ease(.3s);cursor:pointer;}
}

button{background:none;border:none;color:inherit;font-weight:inherit;font-family:inherit;
  border-radius:inherit;//0;
  font-size:inherit;letter-spacing:inherit;
  span.em{font-style:italic;}
  &:hover{cursor:pointer;color:inherit !important;}
}
img{@include center-block();max-width:100%;}
ul{padding-left:20px;margin:inherit;}
ul.none{padding-left:0px;}
hr{border-color:rgba($black, .1);}


.inside, .insideXl, .insideAlt, .insideTw, .medDef, .med2Def, .med3Def, .med4Def, .insideNrw{@include center-block();width:90%;max-width:1800px;}
.insideXl{width:95%;}
.insideAlt{width:85%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}

.pageStyles, .fullRow, .fullSec{@include sec-default();}
.flexRow{width:100%;display:flex;
  &.jbw{justify-content:space-between;}
  &.jc{justify-content:center;}
  &.ja{justify-content:space-around;}
  &.je{justify-content:space-evenly;}
  &.ac{align-items:center;}
}

.pageStyles.tpad{padding-top:30px;}
.centerBlock{@include center-block();}
.textCenter{text-align:center;}

.clearfix::after, .clearfixBtn::after {content:"";clear:both;display:table;}
.clearfixBtn{@include sec-default(); margin-top:10px;}

.ssLink, .ssLinkSm, .ssLinkRev{
  color:$white;
  text-align:center;
  font-size:14px;line-height:1em;letter-spacing:.5px;font-weight:$semi;
  padding:10px 25px 11px;box-shadow:0 10px 25px rgba($black,.15);
  position:relative;z-index:0;
  &, &::before, &::after{border-radius:20px;}
  &::before, &::after{@include before();}
  &::after{background-color:$white;z-index:-2;}
  &::before{background-color:$color1;}
  &:hover{text-decoration:none;color:$white !important;opacity:1;
    &::before{opacity:.85;@include ease(.3s);}
  }

  &.full{@include sec-default();}
  &.up{text-transform:uppercase;}
  &+.ssLink{margin-left:15px;}
  
  @include bp(tb){padding-left:20px;padding-right:20px;}
}
.ssLinkSm{padding:8px 18px 9px;}
.ssLink2::before{background-color:$color2;}
.ssLinkRev{color:$color1;
  &::after{background-color:$color1;}
  &::before{background-color:$white;}
  &:hover{color:$color1 !important;}
}

.textLink{font-weight:bold;position:relative;z-index:0;
  &::before, &::after{@include abs();left:-2%;height:6px;bottom:1px;}
  &::before{width:104%;background-color:rgba($color2,.2);}
  &::after{width:0;background-color:rgba($color2,.6);transition: all .3s;}
  &:hover{opacity:1; color:darken($color1,5%);
    &::after{width:104%;}
  }
}

.dot{display:inline-block;width:3px;height:3px;
  vertical-align:top;margin:10px 3px 0;
  border-radius:50%;background-color:$black;
}

.tabStyles{
  .MuiTabs-flexContainer{grid-gap:20px;}
  button{font-family:$body;}
  button span:last-child{display:none;}
  button[aria-selected="true"]{color:$color2;}
  button:hover{color:$color1 !important;opacity:.7;}
  button[aria-selected="true"]:hover{color:$color2 !important;}
}
.MuiTabs-indicator{background-color:$color2 !important;}

.ilTabs{
  .tabBody, .tabImg{display:inline-block;vertical-align:top;}
  button{padding:0;min-height:unset;}
  button[aria-selected="true"]{text-decoration:underline;text-underline-offset:3px;}
  .MuiTabs-indicator{display:none;}
  
  @include bp(dt){
    .MuiTabs-flexContainer{flex-direction:column;grid-row-gap:15px;}
    .tabLinks{display:inline-block;}

    .tabLinks button{justify-content:flex-start;align-items:flex-start;text-align:left;}
  }
  @include bp(tw){
    .MuiTabs-flexContainer{justify-content:center;flex-wrap:wrap;padding-top:10px;}
    .tabNav{min-height:unset;}
    
    button{min-width:unset;margin:5px 17.5px;z-index:0;overflow:visible;}
    button[aria-selected="true"]::before{@include before();border-radius:50%;background-color:$color2;z-index:1;
      width:6px;height:6px;top:-6px;left:50%;margin-left:-3px;
    }
  }
  @include bp(ft){
    .MuiTabs-flexContainer{justify-content:space-between;}
    button{margin-left:0;margin-right:0;}
  }
  @include bp(og){
    .MuiTabs-flexContainer{
      grid-column-gap:28px;justify-content:center;
      grid-row-gap:10px;
    }
  }
}
