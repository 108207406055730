@import '../../assets/global.scss';

.Social{display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }
  .sitem{display:inline-block;
    &+.sitem{margin-left:10px;}
  }

  // // &.header li{width:17px;}
  &.footer {color:$white;}
  // @include bp(tb){&.header li{width:16px;}}
  // @include bp(xs){&.header li{width:15px;}}
}