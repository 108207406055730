@import '../../assets/global';

.waveGroup{width:100%;fill:$color1;z-index:5;}
.waveGroup.color1{fill:$color1;}
.waveGroup.color2{fill:$color2;}
.waveGroup.white{fill:$white;}
.waveGroup.black{fill:$black;}
.waveGroup.lt2{fill:$bgLt2;}
.waveGroup.lt3{fill:$bgLt3;}

.tl2, .tl3, .bl2, .bl3, .al2{opacity:.5;}
.al3{opacity:.25;}

.waveAlt.flip{@include rotate(180deg);}

.waveGroup.simple{
  path:first-child, path:last-child{display:none;}
  path:nth-child(2){opacity:1;}
}

