@import '../../assets/global';

.modalClose{position:absolute;
  right:15px;top:7px;
  font-size:23px;font-weight:bold;color:$color1;
  
  @include bp(oc){
    right:10px;top:2px;
  }
}

.tourModalWrap{
  .modalBody{padding:40px 0 50px;}
  .top{text-align:center;margin-bottom:10px;}
  h4{font-size:28px;margin-bottom:5px;color:$color1;font-family:$fun;}
  p{width:95%;max-width:650px;@include center-block();}

  @include bp(ft){
    .modalBody{padding:30px 0 20px;}
    .inside{width:95%;max-width:450px;}
    .top{margin:0;}
    h4{font-size:23px;}
    p{font-size:15px;}
  }
  @include bp(fi){h4{font-size:21px;}}
}
