@import './assets/global.scss';

*, *::before, *::after {box-sizing:border-box;}

html {font-size:17px;color:$black;font-family:$body;
  @include bp(nn){font-size:16px;}
  @include bp(og){font-size:15px;}
}
body{margin:0;font-weight:$normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mainWrap{min-height:80vh;}
