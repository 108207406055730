@import '../../assets/global.scss';

.Program{
  @include bp(og){&.tpad{padding-top:20px;}}

  .ps2{padding:100px 0px 70px;text-align:center;position:relative;z-index:0;
    &::before{@include before();height:85px;margin-top:-80px;}
    &, &::before{background-color:$bgLt3;}

    .ssLink, .ssLink+div{display:inline-block;margin:5px 5px;}

    @include bp(nn){padding:85px 0 45px;
      .ps2wrap{max-width:700px;}
    }
    @include bp(og){padding:30px 0;}
  }
  .wave2{margin-top:-5px;}

  .ageBlocks{padding:20px 0 60px; 
    .abWrap{max-width:1200px;}
    
    .block{width:90%;display:block;float:left;margin-top:50px; clear:both;}
    .image, .inBlock{display:inline-block;}
    .image{width:50%;}
    .inBlock{width:45%;max-width:450px;margin-top:7%;position:relative;
      &::after{@include after();width:300px;height:300px;bottom:-70px;opacity:.1;border-radius:50%;}
    }
    .block.b1 .inBlock::after{background-color:$pal1;}
    .block.b2 .inBlock::after{background-color:$pal3;}
    .block.b3 .inBlock::after{background-color:$color2;}
    .block.b4 .inBlock::after{background-color:$pal2;}

    .block:nth-child(odd){margin-left:-4%;
      .image{float:left;}
      .inBlock{margin-left:5%;}
      .inBlock::after{right:-70px;}
    }
    .block:nth-child(even){float:right;margin-right:-5%;
      .inBlock{float:left;text-align:right;margin-right:5%;}
      .inBlock::after{left:-70px;}
    }

    h4{font-size:30px;line-height:1.1em;color:$color1;font-family:$fun;font-weight:900;}
    h4 br, .mobImg{display: none;}

    @media (max-width:1400px){
      .block{width:95%;}
      .block:nth-child(odd){margin-left:0;}
      .block:nth-child(even){margin-right:-3%;}
      h4{font-size:28px;}
    }
    @include bp(tw){
      .inBlock{width:50%;}
      .block:nth-child(odd){
        .inBlock{margin-left:0;padding-left:30px;}
      }
      .block:nth-child(even){margin-right:-1%;
        .inBlock{margin-right:0;padding-right:30px;}
      }
    }

    @include bp(br){padding:0 0 40px;
      .block{width:100%;}
      .inBlock{margin-top:5%;
        &::after{width:230px;height:230px;bottom:-30px;}
      }
      .block:nth-child(odd) .inBlock::after{right:-30px;}
      .block:nth-child(even){margin-right:0;
        .inBlock::after{left:-30px;}
      }
      h4{font-size:24px;margin-bottom:10px;}
    }
    @include bp(og){
      .abWrap{max-width:500px;}
      .block{margin-top:40px;}
      
      .inBlock, .image{width:100%;display:block;}
      .inBlock{margin-top:0;max-width:100%;
        &::after{width:175px;height:175px;bottom:-20px;}
      }
      .block:nth-child(odd) .inBlock, .block:nth-child(even) .inBlock{
        @include sec-default();padding:0;text-align:center;
      }
      .block:not(:first-child) {
        .image{display:none;}
      }
      .image{margin-bottom:20px;}
      h4{font-size:22px;}
      h4+p{margin-bottom:15px;}
      .mobImg{display:block;}
    }

    @media (max-width:600px){
      .inBlock::after{width:150px;height:150px;bottom:0px;}
      .block:nth-child(odd) .inBlock::after{right:-15px;}
      .block:nth-child(even) .inBlock::after{left:-15px;}
    }
  }

  .modBod{text-align: left;
    p.lip{padding-left:15px;margin-bottom:15px;position:relative;line-height:1.4em;
      &::before{@include before();width:7px;height:7px;top:8.5px; background-color:$color2; border-radius:50%;}
    }
    p.listp{margin-bottom:0;}
    ul{padding-left:30px;}
    li{margin-bottom:7px;}
    li::marker{background-color:$color2;color:$color2;}
  }

  .reviewSec{padding:80px 0 50px;
    @media (min-width:1700px){.inner{width:85%;}}

    @include bp(nn){padding:50px 0 30px;}
    @include bp(og){padding:30px 0 20px;}
  }
}