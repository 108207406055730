@import '../../assets/global.scss';

.Careers{
  @include bp(og){&.tpad{padding-top:20px;}}

  .cs2{padding:110px 0px 80px;text-align:center;position:relative;z-index:0;
    padding-bottom:60px;
    &::before{@include before();height:140px;margin-top:-135px;}
    &, &::before{background-color:$bgLt3;}

    .med2Def{max-width:870px;}

    p{font-size:20px;margin-bottom:30px;}
    @include bp(nn){padding:85px 0 50px;
      .med2Def{max-width:670px;}
      p{font-size:17px;margin-bottom:20px;}
    }
    @include bp(og){padding:30px 0 40px;
      .med2Def{max-width:550px;}
    }
    @include bp(oc){padding:20px 0 30px;
      &::before{height:110px;margin-top:-105px;}
      p{font-size:16px;}
    }
  }
  .wave2{margin-top:-5px;}

  .blocksRow{padding:40px 0px 60px;
    .titleRow{@include sec-default();margin-bottom:30px;padding-bottom:50px;text-align:center;position:relative;z-index:0;
      &::after{@include after();width:49.9%;left:0;height:35px;border-right:1px solid rgba($color1,.3);}
    }
    h3{font-size:30px;font-family:$fun;margin-bottom:0;color:$color1;}

    .blocksWrap{max-width:1250px;display:flex;justify-content:space-between;flex-wrap:wrap;}
    p{margin-bottom:0;}

    .block{width:33%;max-width:370px;
      display:inline-flex;align-items:center;grid-column-gap:10px;
      margin-bottom:30px;padding:6px 0;
    
      .icon, svg{width:35px;max-width:35px;height:35px;}
      svg{color:$pal1;}
      p{line-height:1.35em;}
    }

    @media (max-width:1300px){
      .blocksWrap{max-width:1100px;}
      .block{width:30%;max-width:330px;
        flex-direction:column;text-align:center;padding:0;
      }
    }
    @include bp(tb){padding:30px 0px;
      .blocksWrap{width:93%;}
    }
    @include bp(nn){padding:20px 0 15px;
      .titleRow{margin-bottom:20px;padding-bottom:35px;
        &::after{height:25px;}
      }
      h3{font-size:25px;}

      .blocksWrap{max-width:720px;justify-content:space-around;}
      .block{width:50%;max-width:300px;
        .icon, img, svg{width:33px;max-width:33px;height:33px;}
      }
    }
    @include bp(og){
      .titleRow{margin-bottom:15px;padding-bottom:20px;
        &::after{height:15px;}
      }
      h3{font-size:22px;}

      .blocksWrap{max-width:450px;flex-direction:column;justify-content:center;}
      .block{width:100%;max-width:100%;margin-bottom:15px;
        flex-direction:row; text-align:left;
        .icon, svg{width:20px;max-width:20px;height:20px;}
      }
    }
    @include bp(oc){h3{font-size:20px;}}
  }
}