@import '../../assets/global';

.cmOverlay{
  position:fixed;z-index:9998;overflow-y:auto;
  left:0;top:0;width:100%;height:100%;
  padding:50px 30px; background-color:rgba($black, .8);
}

.cmContent{position:relative;z-index:9999;
  width:100%;max-width:1200px;overflow-y:scroll;@include center-block();
  padding:40px 0 35px;background-color:$white;
}
.modalClose{
  position:absolute;top:2px;right:12px;
  font-size:40px;line-height:1em;color:$black;
  &:hover, &:focus {color:$black;text-decoration:none;cursor:pointer;}
}
.modalTitle{@include sec-default();margin-bottom:30px;border-bottom:1px solid rgba($black,.1);text-align:left;
  h4{margin-bottom:15px;}
}

@include bp(og){
  .cmOverlay{padding:20px 10px;}
  .cmContent{padding:25px 0 20px;}
}