@import '../../assets/global.scss';

.Resources{padding-top:30px;
  .infoList{padding:110px 0 70px;position:relative;
    .ilWrap{max-width:1400px;}

    .ilItem{display:inline-block;width:30%;margin:15px 1.5%;padding:0 15px;vertical-align:top;position:relative;z-index:0;
      &::before{@include before();width:40px;height:40px;top:-6px;border-radius:50%;background-color:$pal1;opacity:.3;}

      p{font-family:$fun;}
      a{color:inherit;}
      a:hover{opacity:.7;}
    }
    .ilItem.cta{padding:10px 0;background-color:$color2;text-align:center;
      &::before{display:none;}
      p{color:$white;margin:0;}
    }

    @media (min-width:990px){p{font-family:$fun;font-size:18px;}}
    @include bp(tw){padding:90px 0 50px;}
    @include bp(br){.ilWrap{width:95%;}}
    @include bp(nn){padding-bottom:30px;
      .ilItem{padding: 0 7px;
        &::before{top:-8px;left:-7px;}
      }
    }
    @include bp(og){padding:30px 0 10px;background-color:$bgLt3;
      .ilWrap{width:90%;max-width:500px;}
      .ilItem{width:100%;margin:0 0 10px 0;padding-right:0;
        &::before{width:30px;height:30px;top:-5px;left:-4px;}
      }
      .ilItem.cta{display:none;}
      p{line-height:1.3em;}
    }
  }

  .waveAbs{height:123%;margin-top:-10%;position:absolute;z-index:-1;
    &::before{@include before();height:90%;background-color:$bgLt3;}
    svg{position:absolute;background-color:$white;}
    .wave1 svg{top:-60px;}
    .wave2 svg{bottom:0;}

    @media (max-width:1600px){height:118%;}
    @media (max-width:1450px){margin-top:-12%;}
    @media (max-width:1300px){height:114%;margin-top:-13%;}
    @include bp(tw){&::before{height:95%;}}
    @include bp(nn){margin-top:-15%;
      &::before{height:100%;}
      .wave1 svg{top:-40px;}
    }
    @include bp(tn){height:110%;}
    @include bp(og){height:80px;margin-top:-80px;
      .wave{display:none;}
    }
  }

  .reviewSec{padding:100px 0 60px;
    @media (min-width:1700px){.inner{width:85%;}}

    @include bp(tw){padding-bottom:40px;}
    @include bp(nn){padding-top:70px;}
    @include bp(og){padding:30px 0 30px;}
  }

  // .blocksRow{padding:40px 0px 60px;
  //   .titleRow{@include sec-default();margin-bottom:30px;padding-bottom:50px;text-align:center;position:relative;z-index:0;
  //     &::after{@include after();width:49.9%;left:0;height:35px;border-right:1px solid rgba($color1,.3);}
  //   }
  //   h3{font-size:30px;font-family:$fun;margin-bottom:0;color:$color1;}

  //   .blocksWrap{max-width:1250px;display:flex;justify-content:space-between;flex-wrap:wrap;}
  //   p{margin-bottom:0;}

  //   .block{width:33%;max-width:370px;
  //     display:inline-flex;align-items:center;grid-column-gap:10px;
  //     margin-bottom:30px;padding:6px 0;
    
  //     .icon, svg{width:35px;max-width:35px;height:35px;}
  //     svg{color:$pal1;}
  //     p{line-height:1.35em;}
  //   }

  //   @media (max-width:1300px){
  //     .blocksWrap{max-width:1100px;}
  //     .block{width:30%;max-width:330px;
  //       flex-direction:column;text-align:center;padding:0;
  //     }
  //   }
  //   @include bp(tb){padding:30px 0px;
  //     .blocksWrap{width:93%;}
  //   }
  //   @include bp(nn){padding:20px 0 15px;
  //     .titleRow{margin-bottom:20px;padding-bottom:35px;
  //       &::after{height:25px;}
  //     }
  //     h3{font-size:25px;}

  //     .blocksWrap{max-width:720px;justify-content:space-around;}
  //     .block{width:50%;max-width:300px;
  //       .icon, img, svg{width:33px;max-width:33px;height:33px;}
  //     }
  //   }
  //   @include bp(og){
  //     .titleRow{margin-bottom:15px;padding-bottom:20px;
  //       &::after{height:15px;}
  //     }
  //     h3{font-size:22px;}

  //     .blocksWrap{max-width:450px;flex-direction:column;justify-content:center;}
  //     .block{width:100%;max-width:100%;margin-bottom:15px;
  //       flex-direction:row; text-align:left;
  //       .icon, svg{width:20px;max-width:20px;height:20px;}
  //     }
  //   }
  //   @include bp(oc){h3{font-size:20px;}}
  // }
}