@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Source+Serif+4:ital,opsz,wght@0,8..60,600..900;1,8..60,600..900&display=swap');

$black:#000;
$white:#fff;
$red:#ed1e24;
$gray:#D4D4D4;
$fa:#fafafa;
$bg:#FAF7F3;
$bg2:#FBF9F6;
$bgy:#FFFBF2;

$green0:#549f97;
$eps1: #00512f;
$eps1L:#eaf0ee;
$eps2: #00a78c;
$eps2L:#EAF7F5;
$pdf1:#356a52;
$pdf1L:#EAF0ED;
$pdf2:#51a792;
$pdf2L:#EDF6F4;
$greenBg:#03674d;

// eps pal
$epal1:#FFBA08;
$epal2:#E76F51;
// $epal2b:#DB3E1C;
$epal3:#3F88C5;

$color1:$eps1;
$color2:$eps2;
$bgLt:$eps1L;
$bgLt2:$eps2L;
$bgLt3:#F2FAF9;
$pal1:$epal1;
$pal2:$epal2;
$pal3:$epal3;
$sig:$red;

$source: "Source Serif 4", serif;
$man: "Manrope", sans-serif;
$body: $man;
$fun: $source;

$normal:normal;
$semi:700;
$bold:800;
$hbold:900;