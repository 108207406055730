@import '../../assets/global';

.review{
  .body p{margin-bottom:15px;}
  p.name{font-size:15px;letter-spacing:1px; margin-bottom:0;font-weight:$semi; text-transform:uppercase;}

  &.decor, &.decorG, &.decorB{
    .body p{font-size:21px;font-style: italic;font-family: $fun;line-height: 2em;}
    span{line-height:1.05em;}

    @include bp(tw){
      .body p{font-size:20px;}
      p.name{font-size:14px;}
    }
    @include bp(nn){
      .body p{font-size:18px;}
    }
    @include bp(oc){
      .body p{font-size:17px;}
    }
  }
  &.decor span{background-color:rgba($pal1,.1);}
  &.decorG span{background-color:rgba($color2,.1);}
  &.decorB span{background-color:rgba($pal3,.1);}
}
