@import '../../assets/global.scss';

.addr{
  p.vssLink{margin-bottom:8px;}
  // p.vssLinkSm{margin-bottom:5px;} // no radius on links
  p.vssLinkSm, p.vtextLink{margin-bottom:0px;}
  p.vtextLink+div{margin-top:0px;
    a{font-size:.9em;font-weight:900;}
  }
 
  @include bp(nn){
    p.vtextLink+div a{font-size:.85em;}
  }
}