@import '../../assets/global.scss';

.About{
  @include bp(og){&.tpad{padding-top:20px;}}

  .blocksRow{margin:80px 0px 70px;
    .blocksWrap{max-width:1250px;display:flex;justify-content:space-between;flex-wrap:wrap;}
    p{margin-bottom:0;}

    .block{
      width:33%;max-width:378px;
      display:inline-flex;align-items:center;margin-bottom:30px;
      padding:6px 0;grid-column-gap:10px;
      .icon, svg{width:35px;max-width:35px;height:35px;}
      svg{color:$pal1;}
      p{line-height:1.35em;}      
    }

    @media (max-width:1300px){margin-bottom:50px;
      .blocksWrap{max-width:1020px;}
      .block{width:30%;max-width:330px;flex-direction:column;text-align:center;padding:0;}
    }
    @include bp(nn){margin:60px 0px 25px;
      .blocksWrap{width:93%;}

    }
    @include bp(og){margin:20px 0px 10px;
      .blocksWrap{max-width:600px;flex-direction:column;justify-content:center;}
      .block{width:100%;max-width:100%;margin-bottom:15px;
        flex-direction:row;text-align:left;
        .icon, svg{width:20px;max-width:20px;height:20px;}
      }
    }
  }

  .missionSec{padding:40px 0 45px;position:relative;z-index:0;text-align:center;background-color:$color2;
    h3, p{color:$white;}
    h3{font-size:32px;margin-bottom:10px;font-family:$fun;}
    p{font-size:20px;margin-bottom:0;}

    @include bp(nn){padding:35px 0 40px;
      h3{font-size:24px;}
      p{font-size:17px;}
    }
    @include bp(oc){padding:25px 0 30px;
      h3{font-size:21px;margin-bottom:5px;}
      p{font-size:16px;}
    }
  }
  .wave1+.missionFw{margin-top:-10px;}
  .missionFw+.wave2{margin-top:-5px;}

  .missionMob{display:none;}
  @include bp(og){
    .missionMob{display:block;}
    .missionFw, .wave1, .wave2{display:none;}
  }

  .bgMax{max-width:1850px;@include center-block();}
  .abTabSec{padding:100px 5% 50px;
    @media (max-width:1600px){padding-left:3%;padding-right:4%;}
    @include bp(tw){padding:30px 5%;}
    @include bp(og){padding:0 0 10px;}
  }
  .abTabs{position:relative;
    .tabNav button{font-size:21px;font-family:$fun;text-transform:none;letter-spacing:0;}
    button:not([aria-selected="true"]){color:$color1;}

    .tabLinks, .tabImg{position:sticky;top:20px;}
    .tabBody{width:50%; position:relative;z-index:1;}
    .tabImg{width:28%;max-width:450px;float:right;z-index:0;}
    
    img.mob{display:none;}
    h4{font-size:28px;font-family:$fun;color:$color1;margin-bottom:5px;font-weight:$bold;}
    .hdp{font-size:18px;margin-bottom:0px;font-weight:$bold;color:$color2;}


    .soloTab.boss{
      p.lead{font-size:16px;line-height:1em;margin:0;color:$color2;}
      h4{font-size:32px;margin-bottom:15px;line-height:1.1em;}
      .inline{margin-bottom:18px;}
      .image, .text{display:inline-block;}
      .image{width:49%;float:left;margin-left:-3%;}
      .text{width:52%;float:right;margin-top:12px;margin-right:-3%;}
      p span{font-style:italic;}
    }

    .soloTab.faq{
      .faqItem{@include sec-default();margin-bottom:10px;}
    }

    .soloTab.facility .itWrap{width:90%;@include center-block();}

    @include bp(dt){.tabLinks{width:20%;max-width:250px;float:left;}}
    @media (min-width:1400px){
      .soloTab.ovw{
        .group{
          div{width:48%;display:inline-block;}
          div:first-child{padding-right:15px;}
          div+div{float:right;}
        }
      }

      .soloTab.news .itWrap{width:90%;@include center-block();}
    }
    @media (min-width:1600px){
      .soloTab.boss{
        .image{width:47%;margin-left:0;}
        .text{width:50%;margin-top:8%;margin-right:-2%;}
      }
    }
    
    @media (max-width:1600px){
      .tabBody{width:46%;}
      .tabImg{width:32%;margin-right:-1%;
        &::before{left:-81%;}
      }
    }
    @media (max-width:1400px){
      .soloTab.boss{
        .inline{margin-bottom:0px;}
        .text{margin-top:0;margin-right:-2%;}
      }
    }
    @include bp(tw){
      .tabLinks{margin-bottom:60px;position:relative;top:unset;}
      .tabNav button{font-size:20px;}
      .tabBody{width:62%;padding-right:40px;}
      .tabImg{width:37%;margin-right:0;
        &::before{top:270px;height:320px;}
      }

      .soloTab.boss{
        .image{width:47%;padding-bottom:10px;}
        .text{width:55%;margin-right:-3%;}
      }
    }
    @include bp(nn){
      .tabLinks{margin-bottom:40px;}
      .tabNav button{font-size:18px;}
      .tabBody{width:100%;padding-right:0px;}
      .tabImg{width:100%;max-width:100%;margin-top:30px;position:unset;top:unset;
        &::before{display:none;}
      }
      img{width:auto;max-height:450px;@include center-block();}
      img.mob{display:block;}
      img.fw{display:none;}
      h4{font-size:21px;}

      .soloTab.boss{
        p.lead{font-size:15px;}
        h4{font-size:25px;margin-bottom:8px;}
        .inline{max-width:700px;@include center-block();}
        .image{width:41%;margin-left:-1%;padding-bottom:18px;}
        .text{width:57%;max-width:385px;margin-top:40px;margin-right:0;}
      }
    }

    @media (min-width:640px) and (max-width:989px){
      .soloTab.ovw{
        .group{
          div{width:49%;display:inline-block;}
          div:first-child{padding-right:15px;}
          div+div{float:right;}
        }
      }
    }
    @include bp(og){
      .tabLinks{@include sec-default();padding:5px 15px 12px;margin-bottom:20px;background-color:$bgLt;
        button{font-size:17px;}
      }
      .itWrap{width:90%;@include center-block();}
      .tabImg{display:none;}
      h4{font-size:19px;}
      p{font-size:16px;}

      .soloTab.boss{
        .inline{@include sec-default();}
        .image{width:100%;max-width:200px;}
        .text{width:calc(100% - 205px);max-width:100%; margin-top:5px;margin-right:-5px;}
      }
    }
    @include bp(ph){
      .soloTab.boss{
        h4{font-size:24px;margin-bottom:5px;}
        .image{@include center-block();}
        .text{width:100%;margin:0;}
      }
    }
  }

  .reviewSec{padding:50px 0;
    @include bp(nn){padding:20px 0 30px;}
    @include bp(oc){.insideAlt{width:90%;}}
  }
}